
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

ol {
  padding-left: 30px;
  margin-bottom: 30px;

  li {
    margin-bottom: 10px;
  }
}

img {
  max-width: 100%;
}

a {
  background-color: transparent;
  transition: all .3s ease;
  color: $color-h;

  &:hover {
    text-decoration: none;
  }
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $bg;
  color: $color-text;

  &.over_hide {
    overflow: hidden;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}
th, td {
  padding: 1rem;
  text-align: left;
  border: 1px solid #e2e2e2;
}
tbody tr:nth-child(odd) {
  background: #fbfbfb;
}
tbody tr td:first-child, tbody tr td:last-child {
  text-align: center;
  width: 60px;
}
#review tbody tr td:first-child, #review tbody tr td:last-child {
  text-align: left;
  padding-left: 15px;
}
tbody tr td:first-child {
  padding: 0;
}

#review tbody tr td:first-child {
  padding: 10px 15px;
}

tbody tr td:first-child a {
  width: 60px;
  height: 60px;
  display: block;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: 16px;
  font-weight: 400;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}
input.phone_disabled {
  background: #f4f4f4 !important;
  color: #9c9c9c !important;
  border: 1px solid #ebebeb !important;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

//noinspection ALL,CssOverwrittenProperties
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
textarea {
  height: 42px;
  line-height: 42px;
  background: #fff;
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #ebebeb;
  transition: all .2s ease;

  &:hover {
    border-color: $color-s;
  }

  &:focus {
    border-color: $color-h;
  }
}

textarea {
  min-height: 150px;
}

.m-t {
  margin-top: 30px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #e0e0e0;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #e0e0e0;
}

/* Checkbox + radio
-----------------------------------------------*/
.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.checkbox + label {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
  margin: 5px 0;
  height: 18px;
  display: flex;
  align-items: center;

  & .count_filter {
    color: #c4c4c4;
  }
}

.checkbox + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff url("../img/check.png") 50% 50% no-repeat;
  border: 1px solid #F7F7F7;
  transition: .2s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.checkbox:hover + label:before {
  border-color: var(--red);
}

.checkbox:checked + label:before {
  border-color: var(--red);
  background: var(--red) url("../img/check.png") 50% 50% no-repeat;
}

.radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}

.radio + label {
  position: relative;
  padding: 0 0 0 26px;
  cursor: pointer;
  min-height: 22px;
  line-height: 22px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.radio + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transition: .2s;
  width: 18px;
  height: 18px;
  border: 2px solid #F7F7F7;
  border-radius: 50%;
  background: #FFF;
}

.radio:checked + label:before {
  border-color: var(--red);
  background: var(--red);
}

.radio:hover + label:before {
  border-color: var(--red);
}

.radio + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: .2s;
}

.radio:checked + label:after {
  opacity: 1;
}

button, a, input[type=reset], input[type=button], input[type=submit], input[type=checkbox], input[type=radio], select {
  transition: all 0.15s ease-in-out;
}
.button, .btn {
  @include btn-gradient;
  z-index: 2;

  &:hover {
    @include btn-gradient-hover;
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.3em;
    background: linear-gradient(0deg, rgb(63,110,198) 0%, rgb(45,159,255) 100%);
    transition: opacity 0.2s ease-out;
    z-index: -1;
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
  }

  &.big__btn {
    font-size: 20px;
    font-weight: 300;
    height: 50px;
    padding: 0 40px;
  }

  &.btn__border {
    color: $color-h;
    font-size: 14px;
    font-weight: 600;
    box-shadow: none;
    padding: 0 25px;
    height: 36px;

    &:before {
      content: '';
      background: #fff;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: 2px;
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: -1;
    }

    &:hover span {
      background: linear-gradient(17deg, #fff 0%, #fff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & span {
      background: linear-gradient(17deg, rgb(63,110,198) 0%, rgb(45,159,255) 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &.btn__small {
    height: 34px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 34px;
  }

  &.btn__white {
    background: #fff;
    color: #1d1d1d;
    margin-top: 20px;
    font-weight: 500;
    box-shadow: none;

    &:hover {
      background: #3f6fc7;
      color: #fff;
    }
  }
}

h1 {
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}
