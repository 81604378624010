﻿.container {
  @include min-max;
}

.text-c {
  text-align: center;
}

.header__burger {
  display: none;
}

header {
  position: relative;
  display: flex;
  padding-top: 96px;
  align-items: center;
  justify-content: center;

  &.header__home {
    height: 100vh;
    padding-top: 0;
    min-height: 100%;

    &:before {
      content: '';
      background: url("../img/siterus.png") 50% 50% no-repeat;
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left: 0;
      z-index: -1;
    }
  }

  .top__nav {
    position: absolute;
    width: 100%;
    top:0;
    padding: 30px 0;

    &.scrolled {
      box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.65);
      -webkit-backdrop-filter: blur(55px) saturate(180%);
      backdrop-filter: blur(45px) saturate(180%);
      will-change: auto;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 9;
      padding: 20px 0!important;
      animation: show-top 1 .3s cubic-bezier(.21,.67,.58,1);
      animation-fill-mode: forwards;

      .logo a img {
        width: 150px;
      }
    }

    &.reg_pg {
      .container {
        justify-content: center;
      }
    }

    .container {
      display: flex;
      align-items: center;
    }

    .logo {
      visibility: visible !important;
    }

    .logo a {
      width: 150px;
      display: block;

      img {
        width: 100%;
      }
    }

    .menu__top {
      visibility: visible !important;
      margin-left: 100px;

      ul {
        display: flex;

        li {
          margin: 0 20px;

          a {
            text-decoration: none;
            color: $color-dr;
            font-weight: 600;

            &:hover {
              color: $color-h;
            }
          }

          &.active {

            a {
              color: $color-h;
            }
          }
        }
      }
    }
    .user__menu__top {
      visibility: visible !important;
      flex: 1 1 100%;
      text-align: right;

      a {
        margin-left: 20px;
        text-decoration: none;
        color: $color-dr;
        font-weight: 600;

        &:hover {
          color: $color-h;
        }

        &.btn {
          color: #fff;
          font-weight: 400;
        }
      }
    }
  }

  .header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__content__block:first-child {
      flex: 0 1 100%;

      .header__content__phone {
        font-weight: 300;

        a {
          color: #1b1b1c;
          text-decoration: none;

          &:hover {
            color: $color-h;
          }
        }
      }

      p {
        font-size: 21px;
        line-height: 30px;
        font-weight: 300;
        margin-bottom: 40px;
      }
      h1 {
        margin: 10px 0;
      }
    }
    .header__content__block:last-child {
      width: 568px;
      flex: 1 0 568px;

      img {
        width: 100%;
      }
    }
  }
}

.main__body {

  .main__body__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 200px;

    .main__body__block__item {
      width: 50%;
      flex: 0 1 50%;

      h2 {
        margin-bottom: 20px;
      }

      p {
        font-size: 21px;
        line-height: 30px;
        font-weight: 400;
      }

      &:last-child {
        padding-left: 30px;
      }
    }

    .main__body__block__item.image_bm {

      img {
        width: 100%;
        max-width: 568px;
      }
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;

      .main__body__block__item {


        &:last-child {
          padding-left: 0;
          padding-right: 30px;
        }

        &:first-child {
        }
      }
    }
  }

  &.blog {

    h1 {
      text-align: center;
      font-size: 30px;
    }

    .blog__block {
      display: grid;
      grid-template-columns: repeat(auto-fill,31%);
      grid-gap: 1rem;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 40px 0;

      .block__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 40px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

        .blog__item__img {
          width: 100%;

          a {
            height: 200px;
            width: 100%;
            display: block;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        h2 {
          font-size: 20px;
          font-weight: 700;
          padding:20px;
          line-height: 26px;
        }

        p {
          font-size: 16px;
          color: #6a6a6a;
          padding: 0 20px;
          margin-bottom: 30px;
        }

        .btn {
          margin: auto 20px 20px;
        }
      }
    }

    &.blog__full {
      padding:0 0 40px 0;

      h1 {
        text-align: left;
        margin-top: 0;
        line-height: 38px;
      }

      h2 {
        font-size: 22px;
        margin: 30px 0;
        line-height: 28px;
      }
      .blog__full__block {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .blog__content {
          background: #fff;
          overflow: hidden;
          border-radius: 5px;
          padding: 25px;
          margin-right: 30px;
          width: 100%;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

          img {
            height: auto;
          }
          iframe {
            max-width: 100%;
          }

          a {
            text-decoration: none;
            line-height: 1;
            position: relative;
            z-index: 0;
            display: inline-block;
            padding: 5px 5px;
            overflow: hidden;
            color: rgb(72, 109, 133);
            vertical-align: bottom;
            transition: color .15s ease-out;

            &::before {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              transform: translateY(0);
              width: 100%;
              height: 100%;
              background: $bfh;
              transition: transform .15s ease-out;
            }
            &:hover::before {
              transition: transform .15s ease-out;
              transform: translateY(calc(100% - 2px));
            }
          }

          h3 {
            font-size: 18px;
            margin: 30px 0;
            line-height: 24px;
          }

          p {
            margin-bottom: 30px;
          }

          ul {
            list-style: none;
            padding-left: 30px;
            margin-bottom: 30px;

            li {
              position: relative;
              margin-bottom: 10px;

              &:before {
                content: '';
                width: 8px;
                height: 2px;
                position: absolute;
                left: -20px;
                top: 11px;
                background: $color-h;
                border-radius: 2px;
                transition: all .5s;
              }

              &:hover::before {
                transform: translateX(5px);
              }
            }
          }
        }

        .blog__sidebar {
          flex: 0 0 370px;
          padding: 25px 0;
          background: #fff;
          border-radius: 5px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

          h3 {
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            padding: 0 20px;
          }

          .sidebar__list {
            padding: 20px 0 0;

            .sidebar__item {

              a {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ebebeb;
                padding: 20px;
                font-size: 13px;
                text-decoration: none;
                color: #4b4b4b;

                &:hover {
                  background: $bfh;

                  .sidebar__item__img {
                    box-shadow: 0 2px 4px #b7cddc;
                    transform: translateY(-4px);
                  }
                }

                h4 {
                  font-weight: 600;
                  margin: 0;
                  padding: 0;
                }

                .sidebar__item__img {
                  width: 60px;
                  height: 60px;
                  transition: all .15s ease-in-out;
                  flex: 0 0 60px;
                  overflow: hidden;
                  border-radius: 3px;
                  margin-right: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

              }

              &:last-child a {
                border: 0;
              }
            }
          }
        }
      }
    }
  }

  &.content__all {
    padding-bottom: 100px;

    h1 {
      text-align: center;
      font-size: 30px;
    }

    .prices__block {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .prices__item {
        width: 32%;
        background: #fff;
        padding: 34px 0;
        border: 1px solid #fff;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 40px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

        &:nth-child(2) {
          border-color: #2d9fff;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .prices__name {
          padding: 0 25px;
          font-size: 20px;
          font-weight: 700;

          span {
            color: #a0a0a0;
          }
        }

        .prices__price {
          padding: 50px 25px;
          margin-bottom: 50px;
          font-size: 46px;
          font-weight: 700;
          border-bottom: 1px solid #ebebeb;
          position: relative;

          .prices__vg {
            font-size: 16px;
            font-weight: 700;
            position: absolute;
            bottom: 15px;
            left: 25px;
            background: linear-gradient(17deg, rgb(63,110,198) 0%, rgb(45,159,255) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          span {
            font-size: 22px;
            font-weight: 300;
            color: #a0a0a0;
          }
        }
        .prices__feature {
          font-weight: 300;

          & > div {
            position: relative;
            padding: 5px 30px;

            &.not {
              color: #acacac;
            }
          }

          .ok:before {
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            content: "\f00c";
            background: linear-gradient(17deg, rgb(63,110,198) 0%, rgb(45,159,255) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-right: 7px;
          }

          .not:before {
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            content: "\f00d";
            margin-right: 13px;
          }
        }

        .prices__btn {
          display: flex;
          justify-content: center;
          margin-top: 70px;
        }
      }
    }
  }
}

.test__now {
  background: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px 0;

  h2 {
    font-size: 40px;
    line-height: 46px;
    font-weight: 700;
  }

  p {
    font-size: 21px;
    line-height: 30px;
    font-weight: 400;
    margin: 50px 0;
  }
}

footer {
  padding: 50px 0 0;

  .footer__block {
    display: flex;

    .footer__item {
      width: 25%;
      padding-right: 20px;

      .footer__title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 20px;
      }

      ul li {
        margin-bottom: 10px;

        a {
          color: $btnbg;
          text-decoration: none;

          &:hover {
            color: $color-h;
          }
        }

        span {
          display: block;

          &.phone {
            font-size: 26px;
            font-weight: 700;

            a {
              color: #000;

              &:hover {
                color: $color-h;
              }
            }
          }

          &.phone_dp {
            margin: 5px 0 0;
            color: #696969;
          }
        }
      }
    }
  }
  .social__block {
    background: #eaeaea;
    padding: 40px 0;
    margin-top: 30px;
    text-align: center;

    a {
      color: #fff;
      text-decoration: none;
      padding: 0 20px;
      height: 40px;
      border: 0;
      margin: 0 10px;
      outline: none;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      border-radius: 3px;
      background: linear-gradient(17deg,#4a76a8 0%,#60aaff 100%);
      box-shadow: 0 2px 9px rgba(63, 110, 198, 0.5);
      position: relative;

      i {
        margin-right: 10px;
      }

      &.fb {
        background: linear-gradient(17deg,#29487d 0%,#5283d6 100%);
        box-shadow: 0 2px 9px rgba(63, 110, 198, 0.5);
      }
      &.in {
        background: linear-gradient(17deg,#EF5A39 0%,#A50CA5 100%);
        box-shadow: 0 2px 9px rgba(239, 90, 57, 0.5);

        &:hover {
          box-shadow: 0 2px 2px 0 rgba(239, 90, 57, 0.5);
        }
      }

      &:hover {
        box-shadow: 0 2px 2px 0 rgba(63, 110, 198, 0.5);
      }
    }
  }

  .ft__bt {
    text-align: center;
    padding: 15px 0;
    font-weight: 200;
    color: #616161;
    font-size: 14px;
  }
}

.user__account {
  display: flex;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,.1);
  min-height: 450px;

  h2 {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      font-weight: 500;
      margin-left: 20px;
      border-left: 1px solid #e2e2e2;
      padding-left: 20px;

      a {
        color: #888888;

      }
    }
  }

  .user__account__ct {
    padding: 12px 30px;
    margin-top: 30px;
    background: #f4f4f4;

    &.mobi {
      display: none;
    }

    a {
      color: $color-text;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .user__account__left {
    padding: 40px 0;
    background: #f4f4f4;
    flex: 0 0 370px;

    h2 {
      padding: 0 0 0 30px;
    }

    .tabs {
      display: flex;
      flex-direction: column;

      span.tab {
        border-bottom: 1px solid #e6e6e6;
        padding: 12px 30px;
        color: #515151;
        cursor: pointer;
        font-size: 18px;
        transition: all .15s ease-in-out;

        &:hover {
          background: #efefef;
          color: #1b1b1c;
        }
        &.active {
          background: #ebebeb;
          color: #000;
        }
      }
    }
  }

  .user__account__right {
    padding: 40px 30px;
    width: 100%;

    .user__account__list {

      .account__site__item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f9f9f9;
        padding: 15px 0;

        &:last-child {
          border: 0;
        }

        & > span {
          width: 100%;

          a.del__site {
            font-size: 14px;
            font-weight: 500;
            margin-left: 20px;
            border-left: 1px solid #e2e2e2;
            padding-left: 20px;
            color: #888;
          }

          &:first-child {
            flex: 0 0 280px;
            color: #515151;
          }

          &.no__tariff__selected {
            color: #d93939;

            a {
              margin-left: 20px;
            }
          }

          &.tariff__selected {
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
    }

    .user__account__no__site {
      border:1px dashed #e6e6e6;
      border-radius: 5px;
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h4 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 300;
        color: #484848;
        margin-bottom: 20px;
      }

      a.btn {
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    .prices__block.user__prices {
      flex-wrap: wrap;
      padding-top: 0;

      .prices__feature {
        display: none;
      }

      .prices__item {
        width: 30%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        margin-bottom: 0;

        & > div {
          width: 100%;
        }

        .prices__name {
          font-size: 18px;
          height: 40px;
          padding: 0 5px;

          span {
            display: block;
          }
        }

        .prices__price {
          padding: 30px 5px;
          margin-bottom: 0;
          font-size: 30px;
          border-bottom: 0;

          span {
            font-size: 16px;
          }

          .prices__vg {
            bottom: 0;
            left: 5px;
          }
        }
        .prices__btn {
          flex-direction: column;
          align-items: center;

          .btn {
            margin-bottom: 10px;
          }

          .show__more {
            font-size: 14px;
          }
        }
      }
    }

    .user__orders {
      position: relative;
      height: 100%;

      .order_info {

      }

      .user__order__item {
        transition: all .15s ease-in-out;
        border: 1px solid rgba(228, 228, 228, 0);
        border-top: 0;
        margin-top: -1px;

        &.active {
          padding:0 15px;
          border: 1px solid #e4e4e4;
          border-top: 0;
        }

        &:first-child {
          border-top: 1px solid rgba(228, 228, 228, 0);

          &.active {
            border-top: 1px solid #e4e4e4;
          }
        }

        &:last-child a {
          border: 0;
        }

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #e4e4e4;
          padding: 15px 0;
          text-decoration: none;
          color: #515151;

          &:hover .name__order {
            color: $color-h;
          }

          .name__order {
            transition: all .15s ease-in-out;
            display: flex;
            flex-direction: column;

            span {
              font-size: 14px;
              font-weight: 300;
              color: #a0a0a0;
            }
          }
        }
      }
    }
  }

  .tab_item {
    display: none;
  }
  .tab_item:first-child {
    display: block;
  }
}

.registration {

  h2 {
    position: relative;
    text-align: center;
    margin-bottom: 60px;

    &.reg__f:before {
      content: '';
      position: absolute;
      right: 0;
      animation: loader 2s infinite ease-in-out;
      top: -90px;
      width: 97px;
      background: url(../img/rg.png);
      height: 92px;
    }
  }

  .reg_form.l-step {
    display: none;

    &.active {
      display: block !important;
    }
  }
  .reg_form:first-child {
    display: block;
  }

  .reg_form {
    width: 400px;
    margin: 0 auto;
    position:relative;

    label {
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      span {
        font-size: 12px;
        color: #919191;
      }
    }

    input {
      height: 60px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
      margin: 10px 0;
      width: 100%;
      font-size: 20px;
      font-weight: 300;

      &.novalid {
        border-color: rgba(199, 63, 63, 0.5);
      }

      &.valid {
        border-color: rgb(121, 199, 63);
      }
    }

    .btn {
      width: 100%;
      text-align: center;
      user-select: none;
      display: block;
      height: 60px;
      line-height: 60px;

      &.hide {
        display: none;
      }
      &.show {
        display: block;
      }

      &.dsb {
        pointer-events: none;
      }

      &.novalid {

      }
    }

    &.fs_r .btn {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .reg__footer {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 310px;
    text-align: center;

    .reg__footer__top {
      font-weight: 600;
      margin-bottom: 40px;
    }

    p {
      color: #5f5f5f;
      font-size: 12px;
      line-height: 16px;

      a {
        color: #000000;
      }
    }
  }

  .confirm__tel {
    display: flex;
    align-items: center;
    margin:0 0 10px 0;

    &.confirm__show {
      height: 80px;
    }

    span {
      font-size: 14px;
      line-height: 20px;
      color: #919191;
    }

    input {
      flex: 0 0 125px;
      font-size: 34px;
      font-weight: 800;
    }
  }
  .lost__pass {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    margin: -8px 0 10px 0;

    a {
      color: #5f5f5f;
    }
  }
}
@keyframes loader {
  0% {transform: translateX(0) translateY(0);}
  50%{transform: translateX(5px) translateY(-5px);}
  100%{transform: translateX(0) translateY(0);}
}

.message__reg__error.fancybox-content {
  width: 100%;
  max-width: 500px;
  background: linear-gradient(17deg,#c63f3f 0%,#ff2d2d 100%);
  border-radius: 10px;
  color: #fff;
  text-align: center;
  font-weight: 300;

  .fad {
    font-size: 100px;
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 300;
  }
}

#create-site.fancybox-content {
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  text-align: center;
  padding: 30px 30px 40px;

  h2 {
    margin-bottom: 20px;
    font-size: 26px;
  }

  label {
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .form__field--group {
    position: relative;
    margin-bottom: 20px;

    input.create_inp {
      padding-right: 168px;
    }

    .dom_cr {
      position: absolute;
      right: 0;
      padding: 0 20px;
      height: 42px;
      line-height: 42px;
      bottom: 0;
      pointer-events: none;
      color: #d2d2d2;
      border-left: 1px solid #e2e2e2;
    }
  }

  .btn {
    width: 100%;
    text-align: center;
    display: block;
  }
}

.back__btn {
  display: block;
  text-align: center;
  margin-top: 10px;
  height: 24px;

  span {
    cursor: pointer;
    text-decoration: underline;
    color: #3f6fc7;
    transition: all .15s ease-in-out;
  }

  &:hover span {
    text-decoration: none;
  }
}

.er__ml {
  display: block;
  text-align: center;
  margin-top: 10px;
  height: 24px;

  .message, .message2 {
    display: none;

    &.error {
      color: red;
      font-size: 14px;
      padding: 0;
    }

    &.success {
      color: #37bf1f;
      font-size: 14px;
      padding: 0;
    }
  }
}

.message__reg__success.fancybox-content {
  width: 100%;
  max-width: 500px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-weight: 300;
  background: linear-gradient(17deg,#40c63f 0%,#7ac724 100%);

  .fad {
    font-size: 100px;
  }

  h2 {
    margin-bottom: 20px;
    font-weight: 300;
  }
}

@media (max-width: 1200px) {
  .main__body.content__all .prices__block {
    flex-direction: column;
    align-items: center;
  }
  .main__body.content__all .prices__block .prices__item {
    width: 40%;
  }

  .user__account .user__account__right .prices__block.user__prices {
    flex-direction: row;
  }
}
@media (max-width: 1100px) {
  header .header__content .header__content__block:last-child {
    display: none;
  }
  header .header__content .header__content__block:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  header .top__nav .menu__top {
    margin-left: 40px;
  }
  .user__account {
    flex-direction: column;
  }
  .user__account .user__account__left {
    padding: 0;
    flex: 0 0 auto;
  }
  .user__account .user__account__left h2 {
    display: none;
  }
  .user__account .user__account__left .tabs {
    flex-direction: row;
  }
  .user__account .user__account__left .tabs span.tab {
    width: 25%;
    text-align: center;
    border-top: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;

    &:last-child {
      border-right: 0;
    }
  }
  .user__account .user__account__ct {
    display: flex;
    margin-top: 0;

    &.pc {
      display: none;
    }

    &.mobi {
      display: flex;
      margin: 30px -30px -40px -30px;
    }

    a {
      margin: 0 10px;
    }
  }
  .user__account {
    min-height: 250px;
  }
}

@media (max-width: 991px) {
  header .top__nav .menu__top ul li {
    margin: 0;
  }
  .header .header__burger {
    display: block;
    visibility: visible !important;

    button.burger {
      display: block;
      position: absolute;
      top: 31px;
      right: 50px;
      outline: none;
      background-color: transparent;
      border: none;
      appearance: none;
      z-index: 1600;
      transition: all .3s;
    }

    button.burger.burger_st {
      top: 30px !important;
    }

    button.burger span.box {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: .3s;
    }

    button.burger span.box span.bar {
      right: 0;
      width: 100%;
      height: 2px;
      background-color: #000;
      position: absolute;
      transition: .5s;
      border-radius: 3px;
    }

    button.burger span.box span.bar.top {
      top: 4px;
    }

    button.burger span.box span.bar.middle {
      top: 50%;
      margin: -1px 0 0;
    }

    button.burger span.box span.bar.bottom {
      bottom: 4px;
    }

    button.burger.active span.box span.bar {
      width: 100% !important;
      background-color: #000;
    }

    button.burger.active span.box span.bar.top {
      -webkit-transform: rotate(45deg) translate(9px, 9px);
      -ms-transform: rotate(45deg) translate(9px, 9px);
      transform: rotate(45deg) translate(9px, 9px);
      top: 0;
    }

    button.burger.active span.box span.bar.middle {
      opacity: 0;
    }

    button.burger.active span.box span.bar.bottom {
      -webkit-transform: rotate(-45deg) translate(6px, -7px);
      -ms-transform: rotate(-45deg) translate(6px, -7px);
      transform: rotate(-45deg) translate(6px, -7px);
      bottom: 2px;
    }
  }
  header .top__nav .menu__top {
    position: fixed;
    top: 0;
    right: -420px;
    height: 100vh;
    background-color: #fff;
    width: 420px;
    display: block;
    z-index: 100;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
  }

  .header .top__nav .menu__top.active {
    right: 0;
    display: block;
  }
  header .top__nav, header .top__nav.scrolled {
    padding: 10px 0 !important;
  }
  header .top__nav .user__menu__top {
    padding-right: 70px;
    position: relative;
    z-index: 1000;
  }
  header .top__nav .logo a img {
    width: 150px;
  }
  header .top__nav .menu__top ul {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding:0 15px;

    li {
      font-size: 18px;
      font-weight: 400;
      padding: 0;
      border-bottom: 1px solid #eaeaea;

      a {
        font-weight: 400;
        display: block;
        padding: 20px 0;
      }
    }
  }
  .btn, .button {
    padding: 0 22px;
    height: 34px;
  }

  body {
    font-size: 14px;
  }
  header .top__nav .logo a {
    position: relative;
    z-index: 1000;
  }
  .main__body .main__body__block {
    text-align: center;
    flex-direction: column !important;
  }
  .main__body .main__body__block .main__body__block__item {
    width: 80%;
    flex: 0 1 80%;

    h2 {
      margin-top: 30px;
    }
    &:last-child {
      padding-left: 0;
    }
  }
  .main__body .main__body__block:nth-child(2n) .main__body__block__item:last-child {
    padding-left: 0;
    padding-right: 0;
  }
  .main__body .main__body__block .main__body__block__item.image_bm img {
    width: 100%;
    max-width: 400px;
  }
  .main__body .main__body__block {
    margin-bottom: 100px;
  }

  .main__body .main__body__block .main__body__block__item p br, .main__body .main__body__block .main__body__block__item h2 br {
    display: none;
  }
  .test__now p br {
    display: none;
  }
  footer .footer__block {
    flex-wrap: wrap;
  }
  footer .footer__block .footer__item {
    width: 50%;
    margin-bottom: 30px;
  }
  footer .social__block {
    margin-top: 0;
  }
  .main__body.blog .blog__block {
    grid-template-columns: repeat(auto-fill,48%);
  }
  header {
    padding-top: 84px;
  }
  .main__body.blog .blog__block {
    padding: 30px 0;
  }
  .main__body.blog.blog__full .blog__full__block {
    flex-direction: column;
  }
  .main__body.blog.blog__full .blog__full__block .blog__sidebar {
    margin-top: 30px;
    flex:  0 0 auto;
    width: 100%;
  }
  .main__body.blog.blog__full .blog__full__block .blog__content {
    margin: 0;
  }

  .main__body.content__all .prices__block .prices__item {
    width: 50%;
  }
  .user__account .user__account__left .tabs span.tab {
    font-size: 16px;
    padding: 12px;
  }
}

@media (max-width: 710px) {
  .main__body.blog .blog__block {
    grid-template-columns: repeat(auto-fill,100%);
  }
  .main__body.blog.content__all {
    margin: 0 -15px;
  }
  .main__body.blog .blog__block .block__item {
    border-radius: 0;
    margin-bottom: 20px;
    box-shadow: 0 1px 0 rgba(0,0,0,.1);
  }
  .main__body.blog .blog__block .block__item h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .main__body.blog .blog__block .block__item p {
    font-size: 14px;
    line-height: 20px;
  }
  .btn.btn__border, .button.btn__border {
    font-size: 14px;
  }
  header {
    padding-top: 86px;
  }
  .main__body.blog.blog__full h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .main__body.blog.blog__full h2 {
    font-size: 20px;
    margin: 30px 0;
    line-height: 24px;
  }
  .main__body.blog.blog__full .blog__full__block .blog__content {
    box-shadow:none;
    padding: 15px;
  }
  .main__body.blog.blog__full .blog__full__block .blog__sidebar {
    box-shadow:none;
  }
  .main__body.blog.blog__full {
    margin: 0 -15px;
  }

  .main__body.content__all .prices__block .prices__item {
    width: 100%;
  }
  .user__account .user__account__right .user__account__list .account__site__item {
    flex-direction: column;
  }
  .user__account .user__account__right .user__account__list .account__site__item>span:first-child {
    flex: 0 0 auto;
    font-weight: 700;
  }

  .user__account .user__account__right .prices__block.user__prices {
    flex-direction: column;
  }
  .user__account .user__account__left .tabs span.tab {
    font-size: 14px;
    padding: 12px 0;

    span {
      display: none;
    }
  }
  .user__account .user__account__right {
    padding: 15px;
  }
  .user__account .user__account__right .prices__block.user__prices .prices__item {
    width: 60%;
    margin-bottom: 30px;
  }
  .user__account .user__account__ct.mobi {
    margin: 30px -30px -15px;
  }
}
@media (max-width: 610px) {
  header .top__nav .menu__top {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: #fff;
    width: 100%;
    display: block;
    z-index: 100;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
  }
  header .top__nav .logo a {
    width: 150px;

    & img {
      max-width: 150px;
    }
  }
  h2 {
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
  }
  .main__body .main__body__block .main__body__block__item p {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }
  .main__body .main__body__block .main__body__block__item.image_bm img {
    width: 100%;
    max-width: 310px;
  }
  .main__body .main__body__block .main__body__block__item {
    width: 100%;
    flex: 0 1 100%;
  }
  .main__body.content__all .prices__block .prices__item .prices__feature>div {
    padding: 5px 15px;
  }
  .main__body.content__all .prices__block .prices__item .prices__price {
    padding:30px 15px 50px 15px;
    margin-bottom: 40px;
  }
  .main__body.content__all .prices__block .prices__item .prices__name {
    padding: 0 15px;
  }
  .user__account h2 {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .main__body.content__all .prices__block .prices__item {
    border-radius: 0;
    box-shadow: 0 1px 0px rgba(0,0,0,.1);
    border: 0 !important;
  }
  .main__body.content__all .prices__block .prices__item .prices__btn {
    margin-top: 40px;
  }
  .user__account .user__account__right .prices__block.user__prices .prices__item {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &:last-child {
      box-shadow: none;
    }
  }
  .user__account .user__account__right .prices__block.user__prices .prices__item .prices__btn {
    margin: 0;
    align-items: flex-end;
  }
}

@media (max-width: 550px) {
  header .header__content .header__content__block h1, .test__now h2 {
    font-size: 32px;
    line-height: 38px;
  }
  header .header__content .header__content__block:first-child p {
    font-size: 18px;
    line-height: 25px;
  }
  .test__now p {
    font-size: 18px;
    line-height: 25px;
    margin: 20px 0;
  }

  footer .footer__block .footer__item {
    width: 100%;
    padding: 0;
  }
  footer .social__block a {
    padding: 0 10px;
    height: 36px;
    margin: 0 5px;
  }

  .user__account .user__account__right .user__account__list .account__site__item>span.no__tariff__selected a {
    margin-left: 0;
    display: block;
  }
  .user__account .user__account__right .prices__block.user__prices .prices__item .prices__price {
    font-size: 27px;
  }
  .user__account .user__account__ct.mobi {
    flex-direction: column;
    text-align: center;

    a {
      margin: 0;
    }
  }
  .user__account .user__account__ct.mobi {
    margin: 30px -15px -15px;
  }
}
@media (max-width: 500px) {
  header .top__nav .logo a {
    width: 34px;
    overflow: hidden;
  }
  .btn, .button {
    padding: 0 18px;
    height: 30px;
  }
  header .top__nav .user__menu__top a {
    margin-left: 0;

    &:first-child {
      margin-right: 10px;
    }
  }
  header .top__nav .user__menu__top {
    padding-right: 45px;
  }
  .btn.big__btn, .button.big__btn {
    font-size: 18px;
    font-weight: 300;
    height: 44px;
    padding: 0 25px;
  }

  .header .header__burger button.burger {
    right: 43px;
  }
  .user__account .user__account__right .user__account__list .account__site__item>span a.del__site {
    margin-left: 0;
    border-left: 0;
    padding-left: 0;
    color: #888;
    display: block;
  }
}
@media (max-width: 450px) {
  header .header__content .header__content__block:first-child p, .test__now p {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
  }

  header .header__content .header__content__block:first-child p br {
    display: none;
  }

  header .header__content .header__content__block h1, .test__now h2 {
    font-size: 25px;
    line-height: 29px;
  }
  h2 {
    font-size: 22px;
    line-height: 27px;
  }
  footer .social__block {
    padding: 20px 0;
  }
  footer .social__block a {
    justify-content: center;
    display: inline-flex;
    padding: 0 10px;
    height: 36px;
    width: 36px;
    margin: 10px 5px;
    line-height: 36px;

    i {
      margin: 0;
    }

    span {
      display: none;
    }
  }
  .registration {
    width: 100%;
    padding: 0 15px;
  }
  .registration .reg_form {
    width: 100%;
    position: relative;
  }
  .registration h2:before {
    display: none;
  }
  .registration h2 {
    margin-bottom: 30px;
  }
  .registration .reg_form .btn {
    height: 50px;
    line-height: 50px;
  }
  .registration .reg_form input {
    height: 50px;
    font-size: 18px;
  }
  .registration .reg__footer {
    position: relative;
    bottom: 0;
    top: 100px;
  }
  .registration .reg__footer .reg__footer__top {
    margin-bottom: 20px;
  }
  .registration .reg_form label {
    font-size: 14px;
  }
  .registration .reg_form label span {
    display: none;
  }
  .registration .confirm__tel {
    flex-direction: column;
    align-items: flex-start;
  }
  .registration .confirm__tel input {
    flex: 0 0 50px;
    width: 125px;
  }
  .registration .confirm__tel span {
    font-size: 12px;
    line-height: 16px;
  }

  header.header__home.header__np:before {
    display: none;
  }
}

@media screen and (max-height: 650px) {
  header.header__home.header__np {
    display: block;
    padding-top: 100px;
  }
  .registration .reg__footer {
    bottom: 10px;
    position: relative;
  }
}


@keyframes show-top{
  0%{
    opacity:0;
    transform:translateY(-25px);
  }
  to
  {
    opacity:1;
    transform:translateY(0);
  }
}
